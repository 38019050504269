import React, { useState, useContext } from 'react'

import { Link, navigate } from 'gatsby'

import Snackbar from '@material-ui/core/Snackbar'

import LayoutAuth from '../../components/layout-auth'

import { Formik, Form } from 'formik'
import { object, string, bool } from 'yup'

import { TextField, InputAdornment } from '@material-ui/core'
import { StylesProvider } from '@material-ui/core/styles'

import { AuthDispatchContext, LOG_IN } from '../../context/AuthContextProvider'
import { useAlert, Alert } from '../../hooks/alert'
import { login, getRedirectPath, removeRedirectPath } from '../../services/auth'

import classNames from 'classnames'

import {
  AuthContainer,
  formContainer,
  passwordToggle,
  title,
  fields,
  field,
  fieldError,
  formLink,
  linkLogin,
  submitLogin,
  smallPrint,
} from './auth.module.scss'
import './material-overrides.scss'

const schema = object({
  email: string().email().required(),
  // TODO: What are the min/max password length requirements, etc?
  password: string().required(),
  rememberMe: bool(),
})

const Login = () => {
  const dispatch = useContext(AuthDispatchContext)

  const [showPassword, setShowPassword] = useState(false)

  const [alertOpen, alertMessage, openAlert, closeAlert] = useAlert()

  const handleAlertClose = (event, reason) => closeAlert(reason)

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const user = await login(values)

      if (user && user.username) {
        const redirectPath = getRedirectPath()

        removeRedirectPath()
        setSubmitting(false)
        // The login attempt was successful so... Update the auth context
        dispatch({ type: LOG_IN })

        if (redirectPath) {
          navigate(redirectPath)
        } else {
          navigate('/app/home')
        }
      }
    } catch (err) {
      openAlert(err.message)
    }
  }

  // For Google Analytics
  const trackLoginEvent = (email) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'userDetails',
      clickText: email,
    })
  }

  return (
    <LayoutAuth>
      <div className={AuthContainer} id="content">
        <div className={formContainer}>
          <Formik
            initialValues={{ email: '', password: '', rememberMe: false }}
            validationSchema={schema}
            onSubmit={handleFormSubmit}
          >
            {({ errors, touched, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <span className={title}>Login</span>
                <div className={fields}>
                  <StylesProvider injectFirst>
                    <TextField
                      name="email"
                      id="email"
                      variant="filled"
                      className={classNames({
                        [field]:
                          !touched.email || (touched.email && !!!errors.email),
                        [fieldError]: touched.email && errors.email,
                      })}
                      fullWidth
                      label="Your Email"
                      type="text"
                      placeholder="Enter email"
                      helperText={touched.email ? errors.email : ''}
                      error={touched.email && Boolean(errors.email)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      name="password"
                      variant="filled"
                      className={classNames({
                        [field]:
                          !touched.password ||
                          (touched.password && !!!errors.password),
                        [fieldError]: touched.password && errors.password,
                      })}
                      fullWidth
                      label="Your Password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter password"
                      helperText={touched.password ? errors.password : ''}
                      error={touched.password && Boolean(errors.password)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        // Show password toggle
                        endAdornment: (
                          <InputAdornment position="end">
                            <span
                              className={passwordToggle}
                              onClick={handleShowPassword}
                              onKeyPress={handleShowPassword}
                              tabIndex="0"
                              role="button"
                            >
                              {showPassword ? 'Hide' : 'Show'}
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </StylesProvider>
                </div>
                <div className={submitLogin}>
                  <input
                    id="rememberMe"
                    name="rememberMe"
                    type="checkbox"
                    aria-disabled={isSubmitting}
                    onChange={handleChange}
                  />
                  <label htmlFor="rememberMe">Remember Me</label>
                </div>
                <div className={linkLogin}>
                  <span className={formLink}>
                    <Link to="/auth/forgot-password">Forgot password</Link>
                  </span>
                </div>
                <div className={submitLogin}>
                  <button
                    type="submit"
                    aria-disabled={isSubmitting}
                    onClick={() => {
                      trackLoginEvent(document.querySelector('#email').value)
                    }}
                  >
                    Login
                  </button>
                </div>
                <div className={smallPrint}>
                  By logging in, you are agreeing to Floof’s{' '}
                  <Link to="/terms-of-service">Terms of Service</Link> and{' '}
                  <Link to="//www.mars.com/privacy">Privacy Notice</Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertOpen}
        message={alertMessage}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </LayoutAuth>
  )
}

export default Login
